import React, { Component } from 'react';
import NavBarItem from './navbarItem';
import { withRouter } from "react-router-dom";
import './navstyle.css';

class NavBar extends Component {
  constructor(props) {
    super(props);
    console.log(props.items);
    this.state = {items : props.items};
  }

  generateItem = (item)=> {
    return <NavBarItem text={item.text} url={item.url} />
  };

  navigateHome = () => this.props.history.push('/');

  render() {

    if (this.state.items) {
      var items = this.state.items.map(this.generateItem);
      return (
        <div id="menu-header">
          <ul className="menu">
            <li id="logo-icon" onClick={this.navigateHome}></li>
            {items}
            <li id="donate-wrapper">
            <a className="no-decor big-green-button" href="https://paypal.com/paypalme/vickymakesstuff" target="_blank" id="donate-button">DONATE</a>
            </li>
          </ul>
        </div>
      );
    }
  }
}

export default withRouter(NavBar);
