export const Icons = 
[
    {
        "image": "openmenu",
        "legend": "Open map navigation"
    },
    {
        "image": "park",
        "legend": "Skate park"
    },
    {
        "image": "jam",
        "legend": "Jam skating"
    },
    {
        "image": "trail",
        "legend": "Trail skating"
    },
    {
        "image": "rain",
        "legend": "Rainy day skate"
    }
]

export default class Icon{
    image;
    legend;
}