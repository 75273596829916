import React, { Component } from 'react';
import NavBarItem from './navbarItem';
import './navstyle.css';
import { withRouter } from "react-router-dom";

const MenuHeader = props =>
{
  return (
    <div className="menuExpander"
    aria-expanded={props.isExpanded.toString()}>
      <div id="logo-icon-mobile" onClick={props.handleLogoClick}></div>
      <div onClick={props.handleToggle}
    onKeyDown={props.handleKeyDown}>
      {props.isExpanded ? <div id="menu-icon-expanded"/> : <div id="menu-icon-collapsed"/>}
      </div>
    </div>
  )
}

const MenuBody = props =>
{
    return(
    <div id="collapsibleMenu" aria-hidden={props.isExpanded.toString()}>
        <ul className="menu" onClick={props.handleLinkClick}>
            {props.items}
            <li><a href="https://paypal.com/paypalme/vickymakesstuff" target="_blank">DONATE</a></li>
        </ul>
    </div>)
}

class NavBarMobile extends Component
{
    constructor(props){
        super(props);
        this.state = {items : props.items, isExpanded: false};
        this.handleToggle = this.handleToggle.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
      }

    generateItem = (item)=>
    {
        return <NavBarItem text={item.text} url={item.url}/>
    }

    handleToggle(){
        this.setState({isExpanded: !this.state.isExpanded});
    }

    handleLinkClick(){
        this.setState({isExpanded: false});
    }

    handleLogoClick(){
        this.props.history.push('/');
    }

    handleKeyDown(event){
        if(event.keyCode == 40) {
            event.preventDefault();
            this.setState({
                isExpanded: true
            })
        }

        if(event.keyCode == 38) {
            event.preventDefault();
            this.setState({
                isExpanded: false
            })
        }
    }

    render()
    {
        if (this.state.items)
        {
            return (
                <div className="menuHeader-mobile">
                    <MenuHeader
                        handleToggle={this.handleToggle}
                        handleKeyDown={this.handleKeyDown}
                        handleLogoClick={this.handleLogoClick}
                        isExpanded={this.state.isExpanded}
                    >
                        Open Menu
                    </MenuHeader>
                    <MenuBody
                        isExpanded={!this.state.isExpanded}
                        items = {this.state.items.map(this.generateItem)}
                        handleLinkClick={this.handleLinkClick}
                    >
                    </MenuBody>
                </div>
            );
        }
    }
}

export default withRouter(NavBarMobile);
