export const MissionTenants = [
  {
    title: "Quads and Inlines first",
    tenant: " - We prioritize skate spots and website features for quad and inline skaters. However, all wheels are welcome to use our map to find a good spot to roll."
  },
  {
    title: "Community Knowledge",
    tenant: " - Skaters are our eyes and ears. Your suggestions inform the curation."
  },
  {
    title: "Up to Date",
    tenant: " - We prioritize frequently and recently visited locations."
  },
  {
    title: "Inclusive",
    tenant: " - You belong, you are welcome. Don’t start shit, don’t take shit."
  },
  {
    title: "Newbie-Focused",
    tenant: " - We prioritize beginner-intermediate skaters"
  },
  {
    title: "Skate at your own risk",
    tenant: " - We give you the knowledge and tools to make a decision. We do not officially endorse any skate location or personally verify each skate spot. Be safe, use good judgment, and obey the law. We have a zero tolerance policy against users that harass others, or who submit spots with the intent to harass others there."
  }
]
