import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

export default class NavBarLink extends Component
{
    render = () => {
      return (
        <NavLink
          activeClassName="selectedLink"
          exact to={this.props.url}
          >
            {this.props.text}
        </NavLink>
      );
    }
}
