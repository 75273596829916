import React, { Component } from 'react';
import './skateMap.css';
import {Icons} from './mapIcons';
import {MapIcon} from './mapIcon';

const LegendContainer = props =>
{
    return (
    <div className="legendExpander" 
    onClick={props.handleToggle}
    area-expanded={props.isExpanded.toString()}
    >
        {props.isExpanded ? <div id="legend-icon-expanded"/> : <div id="legend-icon-collapsed"/>}
        <span>MAP LEGEND</span>
    </div>);
}

const LegendBody = props => {
    return(
    <div className="legend-body" aria-hidden={!props.isExpanded}>
      <ul className="map-icons">{Icons.map((icon) =>
      <MapIcon imgClass={icon.image} legend={icon.legend}/>)}
      </ul>
    </div>)
}

export default class LegendMobile extends Component
{
    constructor(props){
        super(props);
        this.state = {isExpanded: false};
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(){
        this.setState({isExpanded: !this.state.isExpanded});
    }

    render(){
        return(
            <div>
            <LegendContainer 
                isExpanded={this.state.isExpanded}
                handleToggle={this.handleToggle}>
            </LegendContainer>
            <LegendBody isExpanded={this.state.isExpanded}></LegendBody>
            </div>
        )
    }
}



