import React, {Component} from 'react';

export default class Home extends Component {
  goToMap = () => {
    this.props.history.push("/map");
  }

 render = () => (
    <div className="content-landing">
      <div id="spacer"></div>
      <div id="title"></div>
      <div className="accent-text-dark" id="landing-center">
        <p>Find spots to roller skate in the Seattle area. Whether you're a jam skater, park skater, trail skater, or a little bit of all, we've got you covered.</p>
      </div>
      <div id="map-button" onClick={this.goToMap}>Go to map
        <i class='fas fa-angle-right'></i>
      </div>
    </div>
  )
}
