import React from 'react';
import {MissionTenants} from "./team/missionTenants"
import './team/mission.css'

export const Mission = () => (
  <div className="content-black">
    <div className="about-header">
      <div id="about-section">
        <div id="about-title"></div>
        <div className="about-text">
          <div className="welcome-text">
            <p>Welcome to WhereToSkate.org! Here you'll find a variety of places to skate near you. These spots are curated for the skate community, by the skate community.</p>
          </div>
          <div className="founding-text">
            <p>WhereToSkate was founded in 2020 by a group of roller skaters in Seattle, Washington.</p>
          </div>
        </div>
    </div>
    </div>
    <div className="accent-text-dark" id="mission-accent">
      <div id="mission-title"></div>
      <p id="mission-text">To help you find safe, accessible and fun places to skate.</p>
    </div>
    <div id="values-section">
      <div id="values-title"></div>
      <div className="mission-tenants">
      <div>
        <ul>
        {MissionTenants.map((mission) => (
          <li className="tenant"><b>{mission.title}</b>{mission.tenant}</li>
        ))}
        </ul>
      </div>
    </div>
  </div>
  </div>
);
