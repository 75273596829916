import React from 'react';
import {Bio} from './team/bio';
import {Bios, Alumni} from './team/bios';
import './team/team.css';

export const Team = () => (
  <div className="content-black">
    <div className="team-header">
      <div id="team-title"></div>
    </div>
    <ul id="bios">{Bios.map((bio) =>
      <Bio name={bio.firstName} text={bio.text} contact={bio.contact} contactUrl={bio.contactUrl} image={bio.image} title={bio.title}/>
    )}
    </ul>
    <div id="alumni-header">WTS ALUMNI</div>
    <ul id="bios">{Alumni.map((bio) =>
      <Bio name={bio.firstName} text={bio.text} contact={bio.contact} contactUrl={bio.contactUrl} image={bio.image} title={bio.title}/>
    )}
    </ul>
  </div>
)
