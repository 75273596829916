import React, { Component } from 'react';
import NavBarLink from './navbarlink';
import './navstyle.css';


export default class NavBarItem extends Component
{
    generateLink = () => {
      //Right now we don't need our class but what if we wanted to change the text, add an arrow or something?
      //Single responsibility principles tell us that it our "Item" should not handle this.
      return (
        <NavBarLink
          id="header-link"
          url={this.props.url}
          text={this.props.text}
        />
      )
    };

    generateContent = () => {
      var content = [this.generateLink()];
      return content;
    };

    render() {
      var content = this.generateContent();
      return (
        <li>
          {content}
        </li>
      );
    }
  }
