import React from 'react';

export function MapIcon(props)
{
  return(
    <div className="map-icon">
      <div className="icon-container">
        <div className={props.imgClass}></div>
      </div>
      <div className="legend">
        <div>{props.legend}</div>
      </div>
    </div>
  )
} 