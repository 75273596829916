import './App.css';
import NavBar from './components/nav/navbar'
import React, { useEffect, useState } from 'react'
import {Route, Switch, withRouter } from "react-router-dom";
import Home from './components/home'
import { Mission } from './components/mission'
import { NoMatch } from './components/nomatch'
import { Team } from './components/team'
import { menuItems } from './components/nav/navitems';
import SkateMap from './components/map/skateMap';
import Popup from "reactjs-popup";
import TCModalContent from './components/TCModalContent';
import ReactGA from 'react-ga';
import { layoutGenerator } from 'react-break';
import NavBarMobile from './components/nav/navbar-mobile';

const layout = layoutGenerator({
  mobile: 0,
  phablet: 580,
  tablet: 768,
  desktop: 992,
});

function App(props)
{
  const[pageClass, setPageClass] = useState("main");
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    var pageName = props.location.pathname === "/" ? "main" : ""
    setPageClass(pageName);
  }, [props.location])
  
  const OnPhablet = layout.isAtLeast('phablet');
  const OnMobile = layout.is('mobile');
  return (
    <div id="main-container">
      <div className={pageClass}>
          <OnPhablet>
            <NavBar items={menuItems}/>
          </OnPhablet>
          <OnMobile>
            <NavBarMobile items={menuItems}/>
          </OnMobile>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/map" component={SkateMap} />
            <Route path="/mission" component={Mission} />
            <Route path="/team" component={Team} />
            <Route component={NoMatch} />
          </Switch>
      </div>
      <div>
        <div className="footer">Wheretoskate is a small team working in their spare time to bring you this map. To help fund the expenses of running the website, we'd love your support <a className="green-footer-link" href="https://paypal.com/paypalme/vickymakesstuff" target="_blank">here</a></div>
          <div className="footer">
            <p>Photographs by <a href="https://www.rebekahgehrke.com/?fbclid=IwAR2NQoWrMwWeSpbYl0xigilyKkMAEXGTXCKmg--U6KWi6dF9LRgNgCY9G9c" target="_blank" className="green-footer-link">Rebekah Gehrke</a></p>
            <p>
              Contact Us:
              <a href="mailto:hello@wheretoskate.org" className="green-footer-link"> hello@wheretoskate.org</a>
            </p>
            <p>
              Follow Us:
              <a href="https://www.instagram.com/wheretoskate" className="green-footer-link"> @wheretoskate</a>
            </p>
            <Popup modal trigger={<div className="button-green">Terms & Conditions</div>}>{close => <TCModalContent close={close} />}</Popup>
          </div>
        </div>
    </div>
  )
}
export default withRouter(App);
