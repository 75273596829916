// Imports
import {
  GoogleApiWrapper
} from 'google-maps-react';
import React from 'react';
import './skateMap.css';
import {Icons} from './mapIcons';
import {MapIcon} from './mapIcon';
import LegendMobile from './legend-mobile';
import {layoutGenerator} from 'react-break';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992,
});

const OnPhablet = layout.isAtLeast('phablet');
const OnMobile = layout.is('mobile');

const Legend = () =>
{
  return(
    <div className="legend-body teal">
      <h3 id="legend-header">MAP LEGEND</h3>
      <ul className="map-icons">{Icons.map((icon) =>
      <MapIcon imgClass={icon.image} legend={icon.legend}/>
      )}
      </ul>
    </div>
  )
}

const SkateMap = ({ location, zoomLevel }) => (
  <div className="map">
    <meta name="viewport" content="width=device-width" />
    <OnPhablet>
      <Legend/>
    </OnPhablet>
    <OnMobile>
      <LegendMobile/>
    </OnMobile>
    <div className="google-map">
      <iframe id="iframe"
        title="WhereToSkateMap"
        src="https://www.google.com/maps/d/embed?mid=1V6-oDrL0xoXyBO9c2wUPHp-A5z3Sdcfm" width="640" height="480">
        </iframe>
    </div>
    <div className="teal text-white" id="suggestions">
      <h2>Want to suggest a new spot?</h2>
      <p>Fill out the form at the link below and we'll review it in our next meeting!</p>
        <a className="no-decor big-green-button" id="suggestions-button" href="https://forms.gle/az3CqL4gXFPZgNxd6" target="_blank">SUGGEST A SPOT</a>
    </div>
  </div>
)

export default GoogleApiWrapper({apiKey: (API_KEY)})(SkateMap);
