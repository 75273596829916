export const Bios = [
    {
        "firstName": "Anya",
        "text": "Backend data engineer by day, finding new surfaces to roll around on by night. Also enjoy snowboarding, taking my kids on skateboarding trips, chess, doing art and hiking with my golden retriever.",
        "contact":  "@Laia_Odo",
        "contactUrl": "https://www.instagram.com/laia_odo/", 
        "image": "anya-img",
        "title": "anya-title"
    },
    {
        "firstName": "Chandler",
        "text": "Data scientist who likes to skate and drink wine (but not at the same time). Always says yes to a slice of pizza.",
        "contact":  "@th0t_wh33lz",
        "contactUrl": "https://www.instagram.com/chan_skates/", 
        "image": "chandler-img",
        "title": "chandler-title"
    },    
    {
        "firstName": "David",
        "text": "A fan of skating and blading with emphasis on slalom. A forever learner with an adorable cat roommate.",
        "contact":  "@certainlynotdavid",
        "contactUrl": "https://www.instagram.com/certainlynotdavid/", 
        "image": "analisa-img",
        "title": "david-title"
    },
    {
        "firstName": "Patrick",
        "text": "Always hungry. Come join me at Judkins Park!",
        "contact":  "@itspchew",
        "contactUrl": "https://www.instagram.com/itspchew/", 
        "image": "patrick-img",
        "title": "patrick-title"
    },
    {
        "firstName": "Vicky",
        "text": "Hobby hobbyist. Collecting favorite concrete and learning to skate since April 2020.",
        "contact":  "@vickymakesstuff",
        "contactUrl": "https://www.instagram.com/vickymakesstuff/", 
        "image": "vicky-img",
        "title": "vicky-title"
    },
];
export const Alumni = [
    {
        "firstName": "Analisa",
        "text": "Brown femme constantly trying to juggle being a professional dancer and software engineer. Also can be found traveling, playing video games, and telling her dog she needs space. Fond of good food, lemon desserts, and cocktails.",
        "contact":  "@tha_beez_neez",
        "contactUrl": "https://www.instagram.com/tha_beez_neez/", 
        "image": "analisa-img",
        "title": "analisa-title"
    },
    {
        "firstName": "Molly",
        "text": " Former derby skater turned skatepark convert. UX designer by day, rolling daredevil by night. On the hunt to find the best ramen in the Seattle area.",
        "contact":  "@mollzrollz",
        "contactUrl": "https://www.instagram.com/mollzrollz/", 
        "image": "molly-img",
        "title": "molly-title"
    },
];
export default class Bio
{
    firstName;
    image;
    text;
    contact;
    contactUrl;
    title;
}