export const menuItems = [
    {
        "text": "HOME",
        "url": "/"
    },
    {
        "text": "MAP",
        "url": "/map"
    },
    {
        "text": "ABOUT",
        "url": "/mission"
    },
    {
        "text": "TEAM",
        "url": "/team"
    }
];

export default class Data
{
    text;
    url;
}