import React from 'react';

export function Bio(props)
{
  return(
    <div className="bio-container">
      <div className="img-container">
        <div className={["bioIcon", props.image].join(' ')}></div>
      </div>
      <div className="bioContent">
        <div className={["bioTitle", props.title].join(' ')}></div>
        <div>{props.text}</div>
        <div className="insta">IG <a href={props.contactUrl}>{props.contact}</a></div>
      </div>
    </div>
  )
} 
